/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, computed, inject, input } from '@angular/core';
import { CerbosBaseDirective } from '../../cerbos.directives';
import { CerbosResource, QuoteModuleActions } from '../../cerbos.policies';
import { QuotePermissionsService } from './quote-resource.service';

export abstract class QuoteResourceCerbosDirective extends CerbosBaseDirective {
	kind = CerbosResource.Quote;
	readonly service = inject(QuotePermissionsService);
}

@Directive({ selector: '[ifAllowOwnersInvites]', standalone: true })
export class IfAllowOwnersInvitesDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.InviteOwners;
}

@Directive({ selector: '[ifAllowContributorsInvites]', standalone: true })
export class IfAllowContributorsInvitesDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.InviteContributors;
}

@Directive({ selector: '[ifAllowAddQuotePart]', standalone: true })
export class IfAllowAddQuotePartDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.AddPart;
}

@Directive({ selector: '[ifAllowRequestApriori]', standalone: true })
export class IfAllowRequestAprioriDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.RequestAprioriEstimate;
}

@Directive({ selector: '[ifAllowRequestManualEstimate]', standalone: true })
export class IfAllowRequestManualEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.AddManualEstimate;
}

@Directive({ selector: '[ifAllowEditManualEstimate]', standalone: true })
export class IfAllowEditManualEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.EditManualEstimate;

	ifAllowEditManualEstimate = input({});
	override attr = computed(() => this.ifAllowEditManualEstimate()) as any;
}

@Directive({ selector: '[ifAllowSearchWithinQuotes]', standalone: true })
export class IfAllowSearchWithinQuotesDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.Search;
}

@Directive({ selector: '[ifAllowDuplicate]', standalone: true })
export class IfAllowDuplicateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.Duplicate;
}

@Directive({ selector: '[ifAllowSaveAndRunSca]', standalone: true })
export class IfAllowSaveAndRunScaDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.SaveAndRunSca;
}

@Directive({ selector: '[ifAllowQuoteClosing]', standalone: true })
export class IfAllowQuoteClosingDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.Close;
}

@Directive({ selector: '[ifAllowAssignQuantity]', standalone: true })
export class IfAllowAssignQuantityDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.AssignQuantity;
}

@Directive({ selector: '[ifAllowAssignRegion]', standalone: true })
export class IfAllowAssignRegionDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.AssignRegion;
}

@Directive({ selector: '[ifAllowQuoteEdit]', standalone: true })
export class IfAllowQuoteEditDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.Edit;
}

@Directive({ selector: '[ifAllowVerificationActions]', standalone: true })
export class IfAllowVerificationActionsDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.VerificationActions;
}

// new
@Directive({ selector: '[ifAllowVerifyManualEstimate]', standalone: true })
export class IfAllowVerifyManualEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.VerifyManualEstimate;
	ifAllowVerifyManualEstimate = input({});
	override attr = computed(() => this.ifAllowVerifyManualEstimate()) as any;
}
@Directive({ selector: '[ifAllowUnverifyManualEstimate]', standalone: true })
export class IfAllowUnverifyManualEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.UnverifyManualEstimate;
	ifAllowUnverifyManualEstimate = input({});
	override attr = computed(() => this.ifAllowUnverifyManualEstimate()) as any;
}

@Directive({ selector: '[ifAllowVerifyAprioriEstimate]', standalone: true })
export class IfAllowVerifyAprioriEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.VerifyAprioriEstimate;

	ifAllowVerifyAprioriEstimate = input({});
	override attr = computed(() => this.ifAllowVerifyAprioriEstimate()) as any;
}

@Directive({ selector: '[ifAllowUnverifyAprioriEstimate]', standalone: true })
export class IfAllowUnverifyAprioriEstimateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.UnverifyAprioriEstimate;

	ifAllowUnverifyAprioriEstimate = input({});
	override attr = computed(() => this.ifAllowUnverifyAprioriEstimate()) as any;
}

@Directive({ selector: '[ifAllowChecklistExecutionActions]', standalone: true })
export class IfAllowChecklistExecutionActionsDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.ChecklistExecutionActions;
}

@Directive({ selector: '[ifAllowQuoteCreate]', standalone: true })
export class ifAllowQuoteCreateDirective extends QuoteResourceCerbosDirective {
	action = QuoteModuleActions.Create;
}
